<template>
  <div id="result-scores-info">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column">
          <span><strong>School Class Name:</strong></span>
          <p class="title is-5 mt-2">{{ schoolClass.name }}</p>
        </div>
        <div class="column is-12">
          <b-field label="Term">
            <b-select v-model="termTitle" placeholder="Select a term" expanded>
              <option v-for="term in ateTerms" :key="term.id">
                {{ term.sessionTitle }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-12">
          <button type="submit" class="button btn-120 is-primary is-capitalized is-pulled-right"
            @click="processResults()">
            Submit
          </button>
        </div>
        <div class="column is-12">
          <template v-if="loading">
            <loading>{{ resultProgress }}</loading>
          </template>
          <template v-else>
            <template v-if="result_path">
              <iframe :src="result_path" />
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'
import TERMS from '../../../graphql/term/Terms.gql'
import { fetchTerm, fetchUser, fetchAppUrl } from '@/assets/js/app_info.js'

export default {
  name: 'result-scores-info',
  data() {
    return {
      pageTitle: 'School Class Results',
      schoolId: null,
      loading: false,
      result_path: null,
      schoolClass: {},
      ateTerms: null,
      termTitle: null,
      termId: null,
      schoolClassId: null,
      path: null,
      asyncJob: null,
      skipQuery: true,
      resultProgress: 'Fetching PDF...',
    }
  },
  apollo: {
    asyncJob: {
      query: gql`
        query asyncJobQuery($id: Int!) {
          asyncJob(id: $id) {
            jobId
            status
            progressCount
            result
          }
        }
      `,
      variables() {
        return { id: parseInt(this.async_job_id) }
      },
      skip() {
        return this.skipQuery
      },
      pollInterval: 500,
    },
    schoolClass: {
      query: gql`
        query schoolClassQuery($id: ID!) {
          schoolClass(id: $id) {
            id
            name
          }
        }
      `,
      variables() {
        return { id: parseInt(this.$route.params.id) }
      },
    },
  },
  watch: {
    termTitle(n) {
      if (!this.ateTerms) return
      const termId = this.ateTerms.filter((term) => term.sessionTitle == n)[0]
        .id
      this.termId = parseInt(termId)
    },
    asyncJob(data) {
      if (data.status === 'Done') {
        this.loading = false
        this.skipQuery = true
        this.result_path = data.result
      } else {
        this.loading = true
        this.resultProgress = data.status
      }
    },
  },
  methods: {
    processResults() {
      fetchAppUrl().then((path) => {
        this.loadPDF(path)
      })
    },
    loadPDF(path) {
      axios
        .get(`${path}/publisher`, {
          params: {
            school_class_id: this.schoolClassId,
            term_id: this.termId,
          },
        })
        .then((response) => {
          this.skipQuery = false
          this.async_job_id = response.data.async_job_id
        })
        .catch((error) => {
          // console.log(error.message);
        })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.schoolClassId = parseInt(this.$route.params.id)
    fetchUser().then((user) => {
      const menus = [
        {
          name: 'School Class Info',
          route: `/school/${this.schoolId}/school_class_info/${this.$route.params.id}`,
        },
      ]
      this.$store.commit('setSubMenus', menus)
    })
    fetchTerm().then((term) => {
      this.termId = parseInt(term.id)
      this.termTitle = term.session_title
    })
    this.$apollo.addSmartQuery('ateTerms', {
      query: gql`
        query AteTermsQuery($schoolClassId: ID!) {
          ateTerms(schoolClassId: $schoolClassId) {
            id
            sessionTitle
          }
        }
      `,
      variables: {
        schoolClassId: this.schoolClassId,
      },
    })
    this.$apollo.queries.ateTerms.refetch()
  },
}
</script>

<style lang="scss" scoped>
iframe {
  display: block;
  border: none;
  height: 100vh;
  width: 100%;
}
</style>
